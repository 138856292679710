import Vue from "vue";
import VueRouter from "vue-router";
import Retrieval from "@/layout/Retrieval.vue";
import Chain from "@/views/Chain.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Retrieval",
    meta: { title: "主页" },
    component: Retrieval,
    redirect: "search",
    children: [
      {
        path: "search",
        name: "Search",
        meta: { title: '专利检索系统', desc: 'Patent retrieval system', img: 0 },
        component: () => import("@/views/Search.vue")
      },
      {
        path: "patent/:id",
        name: "Patent",
        meta: { title: '专利检索系统', desc: 'Patent retrieval system', img: 0 },
        component: () => import("@/views/Patent.vue")
      },
      {
        path: "portrait",
        name: "Portrait",
        meta: { title: '企业专利画像', desc: 'Enterprise patent portrait', img: 3 },
        component: () => import("@/views/Portrait.vue")
      },
      // {
      //   path: "database",
      //   component: () => import("@/views/DataBase.vue")
      // },
      {
        path: "chain",
        name: 'chainIndex',
        meta: { title: '产业链创新链', desc: 'Industrial chain innovation chain', img: 1 },
        component: Chain,
      },
      {
        path: "chain/detail/:id/:name",
        meta: { title: '产业链创新链', desc: 'Industrial chain innovation chain', img: 1 },
        name: "PatDetail",
        component: () => import("@/views/ChainDetail.vue")
      },
      {
        path: "landmark",
        meta: { title: "地理标志", desc: "Trademark search", img: 0 },
        component: () => import("@/layout/LandMark.vue"),
        redirect: "landmark/map",
        children: [
          {
            path: "map",
            name: "LandMark",
            meta: { title: "地理标志", desc: "Trademark search", img: 0 },
            component: () => import("@/views/Map.vue")
          },
          {
            path: "list/:id",
            name: "MarkList",
            meta: { title: "特色产品", desc: "Featured products", img: 0 },
            component: () => import("@/views/MarkList.vue")
          },
          {
            path: "product/:id",
            name: "MarkProduct",
            meta: { title: "特色产品", desc: "Featured products", img: 0 },
            component: () => import("@/views/MarkProduct.vue")
          }
        ]
      },
      {
        path: "trademark",
        name: "TradeMark",
        meta: { title: '商标检索', desc: "Trademark search", img: 0 },
        component: () => import("@/views/Trademark.vue")
      },
      {
        path: "fieldexplain",
        name: 'FieldExplain',
        meta: { title: '分类参考', desc: "Classification reference", img: 2 },
        component: () => import("@/views/FieldExplain.vue")
      },
      {
        path: 'user',
        name: 'User',
        component: () => import("@/views/User.vue")
      }
    ]
  }
];

const router = new VueRouter({
  routes
});

export default router;
