import axios from 'axios'
import store from '../store'
import Notify from 'quasar/src/plugins/Notify.js';import Cookies from 'quasar/src/plugins/Cookies.js';
// 添加请求拦截器
axios.interceptors.request.use(function (config) {
  // 在发送请求之前做些什么
  const token = store.state.token
  if (token && store.state.isLogin) {
    config.headers.authorization = token
  }
  return config;
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error);
});

// 添加响应拦截器
axios.interceptors.response.use(function (response) {
  if (response.data.code === 40001) {
    // console.log(1)
    Notify.create({
      message: '登录过期或未登录，请重新登录',
      position: 'top',
      color: 'negative'
    })
    Cookies.remove('userToken')
    store.dispatch('logout')
  }
  // 对响应数据做点什么
  return response;
}, function (error) {
  // 对响应错误做点什么
  return Promise.reject(error);
});