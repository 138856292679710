import { render, staticRenderFns } from "./Chain.vue?vue&type=template&id=68906360&scoped=true"
import script from "./Chain.vue?vue&type=script&lang=js"
export * from "./Chain.vue?vue&type=script&lang=js"
import style0 from "./Chain.vue?vue&type=style&index=0&id=68906360&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68906360",
  null
  
)

export default component.exports
import QCard from 'quasar/src/components/card/QCard.js';
import QList from 'quasar/src/components/item/QList.js';
import QExpansionItem from 'quasar/src/components/expansion-item/QExpansionItem.js';
import QTabs from 'quasar/src/components/tabs/QTabs.js';
import QTab from 'quasar/src/components/tabs/QTab.js';
import QInnerLoading from 'quasar/src/components/inner-loading/QInnerLoading.js';
import QSpinnerGears from 'quasar/src/components/spinner/QSpinnerGears.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QCard,QList,QExpansionItem,QTabs,QTab,QInnerLoading,QSpinnerGears});
