<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  data () {
    return {

    }
  },
  mounted () {

  },
  methods: {

  }
}
</script>

<style lang="scss">
#app {
  font-family: Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #2c3e50;
}

em {
  color: red;
}

a {
  text-decoration:none;
}

.bg-slrc-primary {
  background: linear-gradient(180deg, #F16868 0%, #DB5252 100%) !important;
  font-size: 20px !important;
  padding: 0 64px !important;
}

.q-btn__wrapper::before {
  box-shadow: initial !important;
}
</style>
