<template>
  <q-layout class="bg" :class="bgImg[bgIdx]" view="hhh lpR fff">
    <q-header style="position: relative; background-color: #ffffff; color: #333333" :reveal="true">
      <q-toolbar class="tool-bar">
        <q-toolbar-title class="tool-bar-title">
          <div>
            <q-avatar style="margin-right: 20px;height: 100%;width:auto;">
              <img class="icon" src="./nav_logo.png"/>
            </q-avatar>
            <span>新疆知识产权公共服务平台</span>
          </div>
          <div class="avatar" v-if="!loading && isLogin">
            <q-avatar :src="userInfo.avatar" :color="userInfo.avatar ? 'white' : 'secondary'" size="45px">
              <q-img spinner-color="primary" spinner-size="20px" :src="userInfo.avatar" v-if="userInfo.avatar" alt=""/>
              <div v-else>{{ userInfo.username ? userInfo.username[0] : '未' }}</div>
            </q-avatar>
            <div class="info"><span class="login-info">您好 {{ userInfo.username || '未知用户' }}，欢迎登录，</span><span @click="logout"
                                                                              style="text-decoration: underline;cursor: pointer;">注销</span>
            </div>
          </div>
          <div class="authBtnGroup" v-else-if="!loading && !isLogin">
            <q-btn class="btnLogin">
              <a style="color: #333333;"
                 href="https://trade.xjip.info/index.php?s=/index/user/unionlogin.html&backurl=https://r.xjip.info/">登录</a>
            </q-btn>
            <div style="margin: 0 5px;"></div>
            <q-btn style="background: #0A3A71;" text-color="white" class="btnLogin register-btn-new">
              <a href="https://trade.xjip.info/index.php?s=/index/user/unionregister.html&backurl=https://r.xjip.info/" style="color: white">注册</a>
            </q-btn>
          </div>
          <div class="avatar" v-else>载入中...</div>
        </q-toolbar-title>
      </q-toolbar>
      <q-tabs class="module-tab-group" indicator-color="transparent" active-color="primary">
        <q-route-tab class="module-tab-item" to="/search" label="专利检索系统"/>
        <q-route-tab class="module-tab-item" to="/portrait" label="企业专利画像"/>
        <q-route-tab class="module-tab-item" to="/chain" label="产业链创新链"/>
        <!-- <q-route-tab to="/database" label="特色专题库" /> -->
        <q-route-tab class="module-tab-item" to="/landmark" label="地理标志"/>
        <q-route-tab class="module-tab-item" to="/trademark" label="商标检索"/>
        <q-tab class="module-tab-item" @click="openLink" label="专利价值评估"/>
        <q-route-tab class="module-tab-item" to='/fieldexplain' label="分类参考"/>
        <!-- <q-route-tab to='/user' label="用户中心" /> -->
      </q-tabs>
    </q-header>
    <div class="mobile-fixer">
      <div class="banner">
        <div style="font-size: 40px; font-weight: 800;">{{ categoryName }}</div>
        <div style="font-size: 16px; font-weight: 500; color: rgba(255,255,255,0.5);">{{ categoryDesc }}</div>
      </div>
      <router-view/>
    </div>
    <!-- <q-footer class="text-center">
          Copyright &copy; 2019-2024 新疆维吾尔自治区知识产权保护中心 All
          Rights Reserved
    </q-footer> -->
    <div class="xjip_footer">
      <div class="xjip_wrap">
        <div class="xjip_footer_info">
          <div class="xjip_footer_qrc">
            <img src="@/assets/xjip-qrc.png" alt="新疆知识产权公共服务平台公众号" title="扫描关注公众号">
          </div>
          <div class="xjip_footer_wrap">
            <p>新ICP备2021000114号</p>
            <p>公安部备65010602000736号</p>
            <p>浏览量: 2987521 <i class="fa fa-signal"></i></p>
          </div>
        </div>
        <div class="xjip_footer_cont">
          <h5>Copyright © 2019-2024 新疆维吾尔自治区知识产权保护中心 All Rights Reserved</h5>
          <p>
            <span>地址: 乌鲁木齐市天山区光明路121号建设广场15层</span>
            <span>邮编: 830000</span>
            <!-- <span>电话: 0991-12345</span> -->
          </p>
          <p>
            <span>友情链接:</span>
            <a href="http://www.cnipa.gov.cn/" target="_blank">国家知识产权局</a>
            <a href="http://www.xjaic.gov.cn/" target="_blank">新疆维吾尔自治区市场监督局(自治区知识产权局)</a>
            <a href="http://ggfw.cnipa.gov.cn:8010/PatentCMS_Center/" target="_blank">国家知识产权公共服务网</a>
          </p>
          <p>
            <span>合作机构:</span>
            <a href="javascript:;">自治区专利信息中心</a>
            <a href="javascript:;">技术与创新支持中心（TISC）-新疆大学</a>
            <a href="javascript:;">新疆知识产权研究与发明协会</a>
            <a href="javascript:;">新疆商标协会</a>
            <a href="javascript:;">国家知识产权培训（新疆）基地</a>
          </p>
        </div>
        <div id="blue">
          <a href="https://bszs.conac.cn/sitename?method=show&amp;id=B4319A1C60654E6AE05310291AACD918">
            <img src="https://dcs.conac.cn/image/blue.png">
          </a>
        </div>
      </div>
    </div>
  </q-layout>
</template>

<script>
export default {
  name: "Retrival",
  data() {
    return {
      loading: true,
      bgImg: ['bg-Img', 'bg-Img-2', 'bg-Img-3', 'bg-Img-4']
    }
  },
  mounted() {
    // document.cookie = 'userToken=eyJ0b2tlbiI6IjhhN2E1NDY3ZDcxNGEyNzM1ZDlkMzEyMzRiYTg2NDY4IiwidXNlcm5hbWUiOiJkd2sifQ%3D%3D'
    const token = this.parse(document.cookie)
    if (token) {
      this.$store.dispatch('setToken', token)
          .then(res => {
            console.log(res)
          })
          .finally(() => {
            this.loading = false
          })
    } else {
      this.loading = false
    }
  },
  methods: {
    openLink() {
      window.open("http://e.cnipr.com")
    },
    logout() {
      this.$store.dispatch('logout')
    },
    parse(str) {
      const arr = str.split(';')
      for (let i = 0; i < arr.length; i++) {
        const arrItem = arr[i].split('=')
        if (arrItem[0].indexOf('userToken') !== -1) {
          return arrItem[1]
        }
      }
    }
  },
  computed: {
    isLogin() {
      return this.$store.state.isLogin
    },
    userInfo() {
      return this.$store.state.userInfo
    },
    categoryName() {
      return this.$route.meta.title
    },
    categoryDesc() {
      return this.$route.meta.desc
    },
    bgIdx() {
      return this.$route.meta.img
    }
  },
};
</script>

<style scoped>
.bg {
  background-repeat: no-repeat;
  background-size: 100% 550px;
  /*background-size: contain;*/
}


.bg-Img {
  background-image: url('../assets/banner1.jpg');
}

.bg-Img-2 {
  background-image: url('../assets/banner3.jpg');
}

.bg-Img-3 {
  background-image: url('../assets/banner7.jpg');
}

.bg-Img-4 {
  background-image: url('../assets/banner2.jpg');
}

img {
  max-width: 100%;
}

.q-tab__content {
  padding: 21px 0 23px 0;
}

.q-tab__label {
  padding: 8px 0;
}

.copyright {
  font-size: 14px;
}

.avatar {
  position: absolute;
  right: 18.75%;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: flex-end;
  z-index: 99;
}

.avatar .info {
  padding: 8px 0;
  margin-left: 10px;
}

.avatar a {
  color: #FFF;
  font-size: 16px;
}

.q-tab {
  padding: 0;
  margin-right: 16px;
}

.xjip_wrap {
  width: 1200px;
  margin: 0 auto;
  padding: 0 10px;
}

.xjip_footer {
  width: 100%;
  height: 150px;
  background-color: #f8f7fa;
  font-size: 12px;
  border-top: 1px solid #EBEEF5;
}

.xjip_footer_info {
  width: 300px;
}

.xjip_footer > .xjip_wrap {
  width: 920px;
  height: 149px;
  padding: 20px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.xjip_footer_info {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.xjip_footer_qrc > img {
  width: 80px;
  height: 80px;
}

.xjip_footer_wrap {
  margin-left: 15px;
}

.xjip_footer_wrap > p {
  line-height: 20px;
  color: #9c9d9d;
  margin:0 !important;
}
.nomrgin{
  margin:0 !important;;
}

.xjip_footer_wrap .fa {
  color: #2184f5;
}

.xjip_footer_cont {
  width: 640px;
  text-align: center;
}

.xjip_footer_cont > h5 {
  line-height: 20px;
  font-size: 14px;
  color: #868686;
  margin: 0;
}

.xjip_footer_cont > p {
  line-height: 23px;
  color: #9c9d9d;
  margin:0px !important;
  /*margin-top: 10px;*/
}

.xjip_footer_cont span {
  margin-right: 2px;
}

.xjip_footer_cont a {
  color: #9c9d9d;
  border-right: 1px solid #9c9d9d;
  padding-right: 3px;
  margin-right: 2px;
  word-break: keep-all;
}

.xjip_footer_cont a:first-child,
.xjip_footer_cont a:last-child {
  border: 0;
}

.xjip_footer_cont a:hover {
  text-decoration: underline;
}

.tool-bar {
  height: 88px;
  padding: 0 18.75%;
  border-bottom: 1px solid #F4F7FA;
  /*background-color: #9C27B0;*/
}

.tool-bar-title {
  font-size: 20px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.authBtnGroup {
  display: flex;
  /*background-color: #21BA45;*/
}
.btnLogin {
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  /*background-color: #9C27B0;*/
}

@media (min-width: 1508px) {
  .module-tab-group {
    padding: 0 18.75%;
  }

  .mobile-fixer {
    padding: 0 18.75%;
  }

  .banner {
    color: #ffffff;
    font-family: SourceHanSansSC-Heavy, SourceHanSansSC;
    margin-top: 60px;
    margin-bottom: 32px;
  }
}
.login-info{

}
@media (max-width: 1200px) {
    .login-info{
      display: none;
    }
}

@media (max-width: 1508px) {
  .tool-bar {
    height: 120px;
    padding: 0 6%;
  }

  .tool-bar-title {
    font-size: 28px;
    font-weight: 500;
    width: 100%;
    height: 80%;
  }

  .btnLogin {
    width: 120px;
    height: 60px;
  }

  .btnLogin a {
    font-size: 24px;
  }

  .icon {
    height: 200px;
    /*background-color: red;*/
  }


  .module-tab-group {
    width: 100%;
    height: 100px;
    font-size: 28px;
    /*background-color: red;*/
  }

  .module-tab-item {
    width: 20%;
    font-size: 200px;
    line-height: 200px;
    /*background-color: yellow;*/
  }

  .mobile-fixer {
    padding: 0 1% 0 2%;
  }

  .banner {
    color: #ffffff;
    font-family: SourceHanSansSC-Heavy, SourceHanSansSC;
    /*margin-top: 60px;*/
    /*margin-bottom: 32px;*/
  }
}

</style>
