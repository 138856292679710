<template>
  <div class="chain">
    <!-- 父容器 -->
    <q-card style="margin-bottom: 100px;" class="row init justify-center" v-show="!nowChainId" :class="listClass">
      <!-- 左侧导航栏 -->
      <div style="padding-top: 24px; box-shadow: 10px 0px 20px 0px rgba(0,0,0,0.04); min-height: 1080px;" class="left-menu-bar">
          <q-list v-for="(item, index) in menu" :key="item.id" bordered class="left-menu-bar-q-list">
            <q-expansion-item
                class="left-menu-first-level-item"
              :icon="item.icon"
              :label="item.name"
              :header-class="item.isShow ? 'text-primary' : ''"
              @input='listItemChange($event,index)'
              :value='item.isShow'
            >
              <q-tabs v-for="item in item.children" :key="item.id" clickable>
                <q-tab
                  @click="updateList(item)"
                  :class="{ 'text-primary': msg.id === item.id, 'slrc-tab-bgc': msg.id === item.id }"
                  v-text="item.name"
                >
                </q-tab>
              </q-tabs>
              <q-inner-loading style="height: 100px" :showing="item.loading">
                <q-spinner-gears size="30px" color="primary" />
              </q-inner-loading>
            </q-expansion-item>
          </q-list>
      </div>
      <!-- 右侧列表栏 -->
      <div class="right-item-container">
        <!-- <router-view /> -->
        <card-list @toDetail='toDetail' :msg="msg" />
      </div>
    </q-card>
  </div>
</template>

<script>
import Chain from "../components/ChainList";

export default {
  name: "Chain",
  components: {
    "card-list": Chain,
  },
  data() {
    return {
      msg: {
        id: 5,
        name: '医药'
      },
      menu: [
      {id: 1, name: "信息技术", icon: "web"},
      {id: 2, name: "大健康", icon: "face"},
      {id: 3, name: "高端制造", icon: "build"},
      {id: 4, name: "能源环保材料", icon: "wb_sunny"},
      {id: 5, name: "现代服务业", icon: "o_thumb_up"},
      {id: 6, name: "大消费", icon: "mdi-alert-circle-outline"},
      {id: 7, name: "重点产业", icon: "construction"},
      {id: 8, name: "八大产业", icon: "precision_manufacturing"}],
      lastShowIndex: '',
      nowChainId: 0,

      detailClass: '',
      listClass: '',
      nowName: ''
    };
  },
  async mounted() {
    // 获取目录
    this.axios.get(process.env.VUE_APP_API_BASE_URL + "/chain").then(res => {
        if (res.data.code === 20000) {
          this.menu = res.data.data
        }
        // console.log(res)
      })
    // this.$http.get("/chain/sidebar/menu.json").then(response => {
    //   this.menu = response.data.menu;
    // });
  },
  methods: {
    updateList(item) {
      this.msg = { id: item.id, name: item.name }
      // if (item.children.length === 0) {
      //   this.msg = item.url;
      // } else {
      //   this.$q
      //     .dialog({
      //       title: "提示",
      //       message: "功能开发中..."
      //     })
      //     .onOk(() => {
      //       // console.log('OK')
      //     })
      //     .onCancel(() => {
      //       // console.log('Cancel')
      //     })
      //     .onDismiss(() => {
      //       // console.log('I am triggered on both OK and Cancel')
      //     });
      // }
    },
    getChainInfo(v) {
      this.$router.push({ name: "PatDetail", params: { id: v } });
    },
    listItemChange (e, index) {
      const itemData = this.menu[index]
      // 手风琴模式
      if (this.lastShowIndex !== '') {
        this.$set(this.menu[this.lastShowIndex], 'isShow', false)
      }
      this.$set(itemData, 'isShow', e)
      this.lastShowIndex = index
      // if (e) {
      //   if (!itemData.children || itemData.children.length === 0) {
      //     this.$set(itemData, 'loading', true)
      //   } else  {
      //     this.$set(itemData, 'loading', false)
      //   }
      //   this.getChainList(itemData.id)
      //     .then(res => {
      //       this.$set(itemData, 'loading', false)
      //       if (res.data.code === 20000) {
      //         this.$set(itemData, 'children', res.data.data)
      //       }
      //     })
      //     .catch(err => {
      //       console.log(err)
      //     })
      // } else {
      //   itemData.loading = false
      // }
    },
    getChainList (e) {
      return new Promise((resolve, reject) => {
        this.axios
          .get(process.env.VUE_APP_API_BASE_URL + "/chain?id=" + e)
          .then(res => {
            resolve(res)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    toDetail (e) {
      console.log(e)
      // click chain item
      this.listClass = 'hide'
      // this.detailClass = 'hide'
      setTimeout(() => {
        this.nowChainId = e.id
        this.nowName = e.name
        this.detailClass = 'show'
      }, 200)
      this.$router.push({ name: 'PatDetail', params: { id: e.id, name: e.name } })
    },
    backList () {
      // 返回列表

      this.detailClass = 'hide'
      // this.listClass = 'hide'
      setTimeout(() => {
        this.nowChainId = 0
      this.listClass = 'show'
      }, 200)
    }
  }
};
</script>

<style scoped>
  .init {
    opacity: 1;
    transition: opacity .5s;
  }
  .show {
    opacity: 1;
  }
  .hide {
    opacity: 0;
  }
  .slrc-tab-bgc {
    background: rgba(198,28,28,0.08);
  }
  .q-list--bordered {
    border: initial;
  }
  .q-card {
    box-shadow: initial;
  }
  @media (min-width: 1508px) {
    .left-menu-bar {
      width: 20%;
    }
    .right-item-container {
      width: 80%;
    }
  }
  @media (max-width: 1508px) {
    .left-menu-bar {
      /*background-color: red;*/
      width: 30%;
      font-size: 28px;
      line-height: 80px;
    }
    .right-item-container {
      /*background-color: green;*/
      width: 70%;
    }
    .left-menu-bar-q-list {
      /*background-color: yellow;*/
    }
    .left-menu-first-level-item{
      /*height: 80px;*/
      /*background-color: blue;*/
      margin-bottom: 10%;
    }
  }
</style>
