import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./quasar";
import axios from "axios";
import VueAxios from "vue-axios";
import echarts from "echarts";
import VueLazyload from "vue-lazyload";
import "./config/axios.config";
// import '../public/layui/layui'
// import '../public/layui/css/layui.css'
// mock
// `mockjs` NOT SUPPORT `IE` PLEASE DO NOT USE IN `production` ENV.

Vue.config.productionTip = false;
Vue.prototype.$echarts = echarts;
Vue.use(VueAxios, axios);
Vue.use(VueLazyload);
// 挂载resize方法
Vue.prototype.$echartsResize = function(ref) {
  window.addEventListener("resize", function() {
    ref.resize();
  });
};

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
