import { render, staticRenderFns } from "./Retrieval.vue?vue&type=template&id=1a107182&scoped=true"
import script from "./Retrieval.vue?vue&type=script&lang=js"
export * from "./Retrieval.vue?vue&type=script&lang=js"
import style0 from "./Retrieval.vue?vue&type=style&index=0&id=1a107182&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1a107182",
  null
  
)

export default component.exports
import QLayout from 'quasar/src/components/layout/QLayout.js';
import QHeader from 'quasar/src/components/header/QHeader.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QToolbarTitle from 'quasar/src/components/toolbar/QToolbarTitle.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QTabs from 'quasar/src/components/tabs/QTabs.js';
import QRouteTab from 'quasar/src/components/tabs/QRouteTab.js';
import QTab from 'quasar/src/components/tabs/QTab.js';
import QFooter from 'quasar/src/components/footer/QFooter.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QLayout,QHeader,QToolbar,QToolbarTitle,QAvatar,QImg,QBtn,QTabs,QRouteTab,QTab,QFooter});
