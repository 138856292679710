import Vue from "vue";
import Vuex from "vuex";
import Loading from 'quasar/src/plugins/Loading.js';import Cookies from 'quasar/src/plugins/Cookies.js';import Notify from 'quasar/src/plugins/Notify.js';
import axios from 'axios'
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: '',
    isLogin: false,
    userInfo: {}
  },
  mutations: {
    setToken (state, data) {
      state.token = data;
    }
  },
  actions: {
    setToken (ctx, data) {
      return new Promise((resolve, reject) => {
        if (data) {
          axios.get(process.env.VUE_APP_API_BASE_URL+'/proxier/utkeep', {
            headers: {
              "authorization": data
            }
          })
          .then(res => {
            if (res.data.code === 20000 || res.data.code === 0) {
              ctx.commit('setToken', data)
              ctx.state.isLogin = true
              axios.get(process.env.VUE_APP_API_BASE_URL+'/proxier/getuserinfo')
                .then(res => {
                  if (res.data.code === 0) {
                    Notify.create({
                      message: '欢迎登入新疆知识产权公共服务平台',
                      position: 'top-right',
                      type: 'positive',
                      color: 'secondary'
                    })
                    ctx.state.userInfo = res.data.user
                    resolve(true)
                  }
                })
            } else {
              reject(false)
            }
          })
        } else {
          reject(false)
        }
      })
    },
    logout (ctx) {
      Loading.show({
        message: '退出中...'
      })
      axios.get(process.env.VUE_APP_API_BASE_URL + '/proxier/logout')
        .then(res => {
          if (res.data.code === 20000) {
            ctx.state.token = ''
            ctx.state.isLogin = false
            ctx.state.userInfo = {}
            Cookies.remove('userToken')
            Loading.hide()
            Notify.create({
              message: '退出登录成功',
              position: 'top-right',
              type: 'positive',
              color: 'black'
            })
          }
        })
    }
  },
  modules: {}
});
