<template>
  <div>
    <!-- <div class="row">
      <div class="col q-mb-sm">
        <q-card
          ><q-card-section
            ><strong>{{ itemName }}创新链知识产权</strong></q-card-section
          ></q-card
        >
      </div>
    </div> -->
    <div style="padding: 24px;" class="row q-col-gutter-lg">
      <div class="col-sm-6 col-md-3" v-for="item in chainData.contents" :key="item.id">
        <q-card class="slrc-card" @click="getChainId(item.id, item.name)">
          <q-img style="width: 100%;" :src="'https://r.xjip.info'+item.img"></q-img>
          <div class="text-center card-title-div">
            <a>{{ item.name }}</a>
          </div>
        </q-card>
      </div>
      <div v-show="chainData.total <= 0">该类目下暂无创新链</div>
    </div>
    <div class="q-pa-lg flex flex-center">
      <q-pagination
          v-if="chainData.pages > 1"
          :max="chainData.pages"
          :value="Number(chainData.pageNum)"
          direction-links
          @input="paginationChange"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "ChainList",
  props: {
    msg: {
      type: Object,
      default: () => {
        return {id: 5, name: '医药'}
      }
    }
  },
  data() {
    return {
      itemName: null,
      current: 1,
      chainData: {
        contents: [],
        total: 1,
        pages: 0,
        pageNum: 1,
        pageSize: 12
      }
    };
  },
  mounted() {
    // this.getIndustryList();
  },
  watch: {
    msg: {
      handler: function () {
        this.itemName = this.msg.name
        this.getIndustryList();
      },
      immediate: true
    }
  },
  methods: {
    getChainId(v, name) {
      this.$emit('toDetail', {id: v, name})
      // this.$router.push({ name: "PatDetail", params: { id: v } });
    },
    getIndustryList() {
      this.$q.loading.show({
        delay: 100,
        message: '加载数据中...'
      })
      this.axios.get(`${process.env.VUE_APP_API_BASE_URL}/chain/subchain?pageSize=${this.chainData.pageSize}&pageNum=${this.chainData.pageNum}&parentid=${this.msg.id}`)
          .then(res => {
            this.$q.loading.hide()
            if (res.data.code === 20000) {
              this.chainData = res.data.data
              if (this.chainData.total === 0) {
                this.$q.notify({
                  message: '该类目下暂无创新链',
                  color: 'secondary',
                  position: 'bottom-left',
                  timeout: 3000
                })
              }
            }
          })
    },
    paginationChange(e) {
      this.chainData.pageNum = e
      this.getIndustryList()
    }
  }
};
</script>

<style scoped>
.q-card {
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  border: 1px solid #F4F7FA;
}

.card-title-div {
  font-size: 16px;
  font-weight: 500;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slrc-card:hover {
  cursor: pointer;
  color: #c61c1c;
  border-color: #c6161c;
  box-shadow: 0px 4px 8px 0px rgb(192 28 28 / 10%)
}

@media (min-width: 1508px) {

  .card-title-div {
    font-size: 16px;
    font-weight: 500;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

}

@media (max-width: 1508px) {
  .card-title-div {
    font-size: 24px;
    font-weight: 500;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>